/* eslint-disable react/jsx-key */
//import UnauthorizedRoute from "base-shell/lib/components/UnauthorizedRoute";
import AuthorizedRoute from "base-shell/lib/components/AuthorizedRoute";

import React, { lazy } from "react";

const SelectStation = lazy(() =>
  import("../pages/SelectStation/SelectStation")
);
const Stations = lazy(() => import("../pages/Stations/Stations"));
const ViewStation = lazy(() => import("../pages/Station/Station"));
const Items = lazy(() => import("../pages/Items/Items"));
const Item = lazy(() => import("../pages/Items/Item"));
const Tasks = lazy(() => import("../pages/Tasks/Tasks"));
const Units = lazy(() => import("../pages/Units/Units"));
const Unit = lazy(() => import("../pages/Units/Unit"));
const Zones = lazy(() => import("../pages/Zones/Zones"));
const Zone = lazy(() => import("../pages/Zones/Zone"));
const Shelfs = lazy(() => import("../pages/Shelfs/Shelfs"));
const Shelf = lazy(() => import("../pages/Shelfs/Shelf"));
const ExportedTasks = lazy(() =>
  import("../pages/ExportedTasks/ExportedTasks")
);
const Places = lazy(() => import("../pages/Places/Places"));
const Station = lazy(() => import("../pages/Stations/Station"));
const Reports = lazy(() => import("../pages/Reports/Reports"));
const MainStation = lazy(() => import("../pages/MainStation/MainStation"));

const routes = [
  {
    path: "/select_station",
    exact: true,
    element: (
      <AuthorizedRoute>
        <SelectStation />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/stations",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Stations />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/station/:stationUid",
    exact: true,
    element: (
      <AuthorizedRoute>
        <ViewStation />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/items",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Items />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/create_item",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Item />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/items/edit/:uid",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Item />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/tasks",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Tasks />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/units",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Units />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/create_unit",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Unit />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/units/edit/:uid",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Unit />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/zones",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Zones />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/create_zone",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Zone />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/zones/edit/:uid",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Zone />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/shelfs",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Shelfs />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/create_shelf",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Shelf />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/shelfs/edit/:uid",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Shelf />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/exported_tasks",
    exact: true,
    element: (
      <AuthorizedRoute>
        <ExportedTasks />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/places",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Places />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/create_station",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Station />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/stations/edit/:uid",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Station />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/reports",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Reports />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/main_station/:type",
    exact: true,
    element: (
      <AuthorizedRoute>
        <MainStation />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/main_station/:type/:taskUid",
    exact: true,
    element: (
      <AuthorizedRoute>
        <MainStation />
      </AuthorizedRoute>
    ),
  },
];

export default routes;
