const locales = [
  {
    locale: "en",
    messages: import("./en"),
    image: "/en.png",
  },
  {
    locale: "de",
    messages: import("./de"),
    image: "/de.png",
  },
  {
    locale: "bs",
    messages: import("./bs"),
    image: "/bs.png",
  },
  {
    locale: "ro",
    messages: import("./ro"),
    image: "/ro.png",
  },
];

export default locales;
