export const grants = [
  "create_item",
  "edit_item",
  "delete_item",
  "read_items",
  "create_unit",
  "edit_unit",
  "delete_unit",
  "read_units",
  "create_zone",
  "edit_zone",
  "delete_zone",
  "read_zones",
  "read_tasks",
  "create_station",
  "edit_station",
  "delete_station",
  "read_stations",
  "create_shelf",
  "edit_shelf",
  "delete_shelf",
  "read_shelfs",
  "create_task_item",
  "edit_task_item",
  "delete_task_item",
  "read_task_items",
  "read_released_task",
  "edit_released_task",
  "read_main_station",
  "read_evaluation",
];

export default grants;
