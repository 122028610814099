import red from "@mui/material/colors/red";
import pink from "@mui/material/colors/pink";
import green from "@mui/material/colors/green";
import orange from "@mui/material/colors/orange";

const themes = [
  {
    id: "default",
    color: green[900],
    source: {
      palette: {
        primary: {
          main: "#1b5e20",
        },
        secondary: {
          main: "#e65100",
        },
      },
    },
  },
  {
    id: "red",
    color: red[500],
    source: {
      palette: {
        primary: red,
        secondary: pink,
        error: red,
      },
    },
  },
  {
    id: "green",
    color: green[500],
    source: {
      palette: {
        primary: green,
        secondary: orange,
        error: red,
      },
    },
  },
  {
    id: "standard",
  },
];

export default themes;
