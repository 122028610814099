import { lazy } from "react";
import locales from "./locales";
import routes from "./routes";
import themes from "./themes";
import parseLanguages from "base-shell/lib/utils/locale";
import grants from "./grants";
import Loading from "material-ui-shell/lib/components/Loading/Loading";
import { getDefaultRoutes } from "./getDefaultRoutes";
import {
  defaultUserData,
  isGranted,
  isAnyGranted,
} from "rmw-shell/lib/utils/auth";

const config = {
  firebase: {
    prod: {
      initConfig: {
        apiKey: "AIzaSyAZctvExo_vhHtuABKv_OH5y51JnjAtiQY",
        authDomain: "hanskom-6ce46.firebaseapp.com",
        databaseURL: "https://hanskom-6ce46.firebaseio.com",
        projectId: "hanskom-6ce46",
        storageBucket: "hanskom-6ce46.appspot.com",
        messagingSenderId: "909040584997",
        appId: "1:909040584997:web:d127134b5f4f262982f2cc",
      },
      messaging: {
        publicVapidKey:
          "BL3hkYY_q46UatnWWgntC1u43qWVJNxkYPUpt7oQJ_jPdxpzX6htmeX-wyh2CQrW8DAtmqqqpzFUW64NxHkjH8s",
      },
    },
    dev: {
      initConfig: {
        apiKey: "AIzaSyAZctvExo_vhHtuABKv_OH5y51JnjAtiQY",
        authDomain: "hanskom-6ce46.firebaseapp.com",
        databaseURL: "https://haenskom-dev.firebaseio.com",
        projectId: "hanskom-6ce46",
        storageBucket: "hanskom-6ce46.appspot.com",
        messagingSenderId: "909040584997",
        appId: "1:909040584997:web:d127134b5f4f262982f2cc",
      },
      messaging: {
        publicVapidKey:
          "BL3hkYY_q46UatnWWgntC1u43qWVJNxkYPUpt7oQJ_jPdxpzX6htmeX-wyh2CQrW8DAtmqqqpzFUW64NxHkjH8s",
      },
    },
    devd: {
      initConfig: {
        apiKey: "AIzaSyCz1zSm_tukjnj-FZ9pz0YPDuChPoSKdNI",
        authDomain: "hanskom-dev.firebaseapp.com",
        databaseURL: "https://hanskom-dev.firebaseio.com",
        projectId: "hanskom-dev",
        storageBucket: "hanskom-dev.appspot.com",
        messagingSenderId: "506480546822",
        appId: "1:506480546822:web:d5817f653d66f60b",
        measurementId: "G-07H10RP6KE",
      },
      messaging: {
        publicVapidKey:
          "BL3hkYY_q46UatnWWgntC1u43qWVJNxkYPUpt7oQJ_jPdxpzX6htmeX-wyh2CQrW8DAtmqqqpzFUW64NxHkjH8s",
      },
    },
    firebaseuiProps: {
      signInOptions: ["password"],
    },
  },
  googleMaps: {
    apiKey: "X",
  },
  auth: {
    grants,
    redirectTo: "/select_station",
    persistKey: "base-shell:auth",
    signInURL: "/signin",
    onAuthStateChanged: async (user, auth) => {
      const { getDatabase, ref, onValue, get, update, off } = await import(
        "firebase/database"
      );
      const db = getDatabase();

      try {
        if (user != null) {
          const grantsSnap = await get(ref(db, `user_grants/${user.uid}`));
          const notifcationsDisabledSnap = await get(
            ref(db, `disable_notifications/${user.uid}`)
          );

          const isAdminSnap = await get(ref(db, `admins/${user.uid}`));

          onValue(ref(db, `user_grants/${user.uid}`), (snap) => {
            auth.updateAuth({ grants: snap.val() });
          });
          onValue(ref(db, `disable_notifications/${user.uid}`), (snap) => {
            auth.updateAuth({ notificationsDisabled: !!snap.val() });
          });
          onValue(ref(db, `admins/${user.uid}`), (snap) => {
            auth.updateAuth({ isAdmin: !!snap.val() });
          });

          auth.updateAuth({
            ...defaultUserData(user),
            grants: grantsSnap.val(),
            notificationsDisabled: notifcationsDisabledSnap.val(),
            isAdmin: !!isAdminSnap.val(),
            isGranted,
            isAnyGranted,
          });

          update(ref(db, `users/${user.uid}`), {
            displayName: user.displayName,
            uid: user.uid,
            photoURL: user.photoURL,
            providers: user.providerData,
            emailVerified: user.emailVerified,
            isAnonymous: user.isAnonymous,
            notificationsDisabled: notifcationsDisabledSnap.val(),
          });
        } else {
          off(ref(db));

          auth.setAuth(defaultUserData(user));
        }
      } catch (error) {
        console.warn(error);
      }
    },
  },
  getDefaultRoutes,
  routes,
  locale: {
    locales,
    persistKey: "base-shell:locale",
    defaultLocale: parseLanguages(["en", "de"], "en"),
    onError: (e) => {
      //console.warn(e)

      return;
    },
  },
  menu: {
    MenuContent: lazy(() => import("../components/Menu/MenuContent")),
    MenuHeader: lazy(() =>
      import("material-ui-shell/lib/components/MenuHeader/MenuHeader")
    ),
  },
  theme: {
    themes,
    defaultThemeID: "default",
    defaultType: "dark",
  },
  pages: {
    LandingPage: lazy(() => import("../pages/LandingPage")),
    PageNotFound: lazy(() => import("../pages/PageNotFound")),
  },
  components: {
    Menu: lazy(() => import("material-ui-shell/lib/containers/Menu/Menu")),
    Loading,
  },

  containers: {
    LayoutContainer: lazy(() =>
      import("rmw-shell/lib/containers/LayoutContainer/LayoutContainer")
    ),
  },
};

export default config;
